<template>
  <div class="card vue-avatar-cropper-demo text-center">
    <div class="card-body">
      <img :src="activeUserImg" class="card-img avatar" />
      <div class="card-img-overlay">
        <el-button class="text-base" type="primary" style="background-color: #003f8e" id="pick-avatar">{{$t('SelectFile')}}</el-button>
        <p class="my-3 mx-auto" style="color:red">
           {{$t('selectFileNoticeMessage')}}
        </p>
      </div>
    </div>
    <div class="card-footer text-muted" v-html="message"></div>
    <avatar-cropper
        @uploading="handleUploading"
        @uploaded="handleUploaded"
        @completed="handleCompleted"
        @error="handlerError"
        trigger="#pick-avatar"
        :upload-url="action"
        :upload-headers="{
          Authorization: token
        }" />
  </div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";

export default {
  components: { AvatarCropper },
  props: ['dimage', 'action'],
  data() {
    return {
      message: "",
      token: 'Bearer ' + localStorage.getItem('di_tokenId'),
      user: {
        id: 1,
        nickname: "姓名",
        username: "overtrue"
      }
    };
  },
  computed: {
    activeUserImg() {
      return this.$store.getters.avatar
    },
  },
  methods: {
    // 上傳中
    handleUploading(form, xhr) {
      this.message = this.$t('Uploading')+"...";
    },
    // 上傳
    handleUploaded(response) {
      var _self = this
      // console.log('upload msg >> response', response)
      if (response.status == "OK") {
        var photoname = response.filename
        _self.$store.commit('SET_AVATAR', process.env.VUE_APP_UPLOAD+'/doctor/photo/160/'+photoname)
        _self.message = _self.$t('UploadFinished');
      }
    },
    // 上傳完成
    handleCompleted(response, form, xhr) {
      this.message = this.$t('UploadFinished');
    },
    // 上傳失敗
    handlerError(message, type, xhr) {
      this.message = this.$t('message.upload_error');
    }
  }
};
</script>

<style>
  .vue-avatar-cropper-demo {
    max-width: 18em;
    margin: 0 auto;
  }
  .avatar {
    width: 160px;
    border-radius: 6px;
    display: block;
    margin: 20px auto;
  }
  .card-img-overlay {
    /* display: none; */
    transition: all 0.5s;
  }
  /* .card-img-overlay button {
    margin-top: 20vh;
  } */
  .card:hover .card-img-overlay {
    display: block;
  }
</style>
